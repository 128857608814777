import type { TextColor } from "@10xdev/types";
import { colorBlueDark, colorBlueMedium } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { LinkProps } from "next/link";
import Link from "next/link";
import type { ComponentProps } from "react";
import { forwardRef } from "react";

import { colors } from "../Text";

type PassThroughLinkProps = Pick<LinkProps, "href" | "scroll" | "locale">;

interface StyleProps {
  /** An enumerated color. */
  color?: TextColor;

  /** Determines if the user can interact with the link. */
  enabled?: boolean;

  /** Hover color, if different from the primary color. */
  hoverColor?: string;

  /** A flag indicating whether or not text should be underlined on hover. */
  underlineOnHover?: boolean;
}

const anchorCss = ({
  color = "blue",
  enabled = true,
  hoverColor,
  underlineOnHover,
}: StyleProps) => {
  const colorToken = color && colors[color];

  // If primary color is inherited, use for hover also.
  // Although if hoverColor is explicitly defined, honor it.
  const getHoverColor = () => {
    if (colorToken === "inherit" && !hoverColor) {
      return "inherit";
    }
    return hoverColor || colorBlueDark;
  };

  return css`
    color: ${colorToken || colorBlueMedium};
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    pointer-events: ${enabled ? "all" : "none"};
    text-decoration: none;

    &:hover {
      color: ${getHoverColor()};
      text-decoration: ${underlineOnHover ? "underline" : "none"};
    }

    /* Provide a fallback style for browsers
     that don't support :focus-visible */
    &:focus {
      outline: 2px auto;
    }

    /* Remove the focus indicator on mouse-focus for
     browsers that support :focus-visible */
    &:focus:not(:focus-visible) {
      outline: none;
    }

    /* Focus style for keyboard-focus on browsers
     that support :focus-visible */
    &:focus-visible {
      outline: 2px auto;
    }
  `;
};

const StyledA = styled("a")(anchorCss);

/**
 * An inline element that links to somewhere else.
 * Content is text that indicates the destination.
 *
 * This must be used inside a block-level text
 * component such as a `Paragraph` because it
 * wants to inherit the block's styles. The
 * `Anchor` matches text around itself.
 */

export type Props = PassThroughLinkProps &
  Omit<ComponentProps<typeof StyledA>, "href" | "ref">;

export const Anchor = forwardRef<HTMLAnchorElement | null, Props>(
  ({ href, locale, scroll, ...aProps }, ref) => {
    const rel = aProps.target === "_blank" ? "noopener noreferrer" : undefined;

    return (
      <Link href={href} legacyBehavior locale={locale} passHref scroll={scroll}>
        <StyledA rel={rel} {...aProps} ref={ref} />
      </Link>
    );
  },
);

export default Anchor;
