import { css } from "@emotion/react";
import type { ReactNode, RefObject } from "react";
import { forwardRef } from "react";

interface Props {
  children: ReactNode;
  className?: string;
  onClick: () => void;
  ref: RefObject<any>;
  style?: any;
}

// https://github.com/yannickcr/eslint-plugin-react/issues/3015
const IconButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { children, className, onClick, style } = props;
  return (
    <button
      className={className}
      css={css`
        background: none;
        border: none;
        cursor: pointer;
      `}
      onClick={onClick}
      ref={ref}
      style={style}
      type={"button"}
    >
      {children}
    </button>
  );
});

export default IconButton;
