import { css } from "@emotion/react";
import { lowerCase } from "lodash-es";
import type { FunctionComponent } from "react";

import type { IconProps } from "./constants";
import { icons } from "./constants";
import type { IconColor, IconSource } from "./types";
import { breakPoints, colors } from "./utils";

interface SizeWithBreakpoints {
  desktop: string;
  phone: string;
  tablet: string;
  widescreen: string;
}

function isSizeWithBreakpoints(
  size: SizeWithBreakpoints | string,
): size is SizeWithBreakpoints {
  return (size as SizeWithBreakpoints).desktop !== undefined;
}

export type Props = {
  /** An enumerated color. */
  color?: IconColor;

  /**
   * Size of the icon width and height in either px (default),
   * em, rem or percentage. Use unit prop to change if needed.
   * If no size is declared, icon will be 100%.
   */
  size: string | SizeWithBreakpoints;

  /** An enumerated icon. */
  source: IconSource;

  /** Nudge an icon left/right using neg/pos number in pixels */
  xPos?: string;

  /** Nudge an icon up/down using neg/pos number in pixels */
  yPos?: string;
} & Omit<IconProps, "size" | "style">;

const Icon: FunctionComponent<Props> = ({
  color = "inherit",
  size = "1rem",
  source,
  title,
  xPos = "0px",
  yPos = "0px",
  className,
  ...iconProps
}) => {
  const Icon = icons[source];
  const position = `${xPos}, ${yPos}`;
  const { desktop, tablet, widescreen } = breakPoints;

  const phoneSize = isSizeWithBreakpoints(size) ? size.phone : size;
  const tabletSize = isSizeWithBreakpoints(size) ? size.tablet : size;
  const desktopSize = isSizeWithBreakpoints(size) ? size.desktop : size;
  const widescreenSize = isSizeWithBreakpoints(size) ? size.widescreen : size;

  const iconCss = css`
    fill: ${colors[color]};
    height: ${phoneSize};
    transform: translate(${position});
    width: ${phoneSize};

    @media (min-width: ${tablet}) {
      height: ${tabletSize};
      width: ${tabletSize};
    }

    @media (min-width: ${desktop}) {
      height: ${desktopSize};
      width: ${desktopSize};
    }

    @media (min-width: ${widescreen}) {
      height: ${widescreenSize};
      width: ${widescreenSize};
    }
  `;

  if (!Icon) {
    return null;
  }

  return (
    <Icon
      aria-label={`${lowerCase(source)} icon`}
      className={className}
      css={iconCss}
      role={"img"}
      title={title}
      {...iconProps}
    />
  );
};

export default Icon;
